import React from "react";

import {
  CookiePreferencesContainer,
  CookiePreferencesContainerProps,
} from "@interactive-investor/onestack-web-shared";

import Layout from "../../components/layout";

class CookiePreferencesPage extends React.Component<{}, {}> {
  render() {
    const props: any = {} as CookiePreferencesContainerProps;

    return (
      <Layout>
        <CookiePreferencesContainer {...props} />
      </Layout>
    );
  }
}

export default CookiePreferencesPage;
